import React from 'react'

import get from 'lodash/get'

import { Location } from '@reach/router'

import { AuthProvider } from 'stores/auth'
import parseQueryString from 'utils/parse-querystring'

/**
 * Example of usage:
 ```
 const MODALS = [
 { test: /edit_name/, Component: EditNameModal },
 { query: /confirmation_code/, Component: EmailVerificationModal }
 ]

 <MatchQueryModal modalsList={MODALS} />
 ```
 */
const MatchQueryModal = ({ modalsList = [], ...modalProps }) => (
  <Location>
    {props => {
      const pathname = get(props, 'location.pathname', '')
      const queryString = get(props, 'location.search', '')
      const query = parseQueryString(queryString)

      const Modal = modalsList.find(modal => {
        if (modal.query) {
          return get(query, modal.query)
        }

        return pathname.match(modal.test)
      })

      return (
        <AuthProvider>
          {store => {
            const isLoggedIn = store.isLoggedIn()
            const hideLoggedIn = get(Modal, 'props.hideLoggedIn')

            if (hideLoggedIn && isLoggedIn) return null

            if (get(Modal, 'Component')) {
              const { props = {} } = Modal || {}
              return <Modal.Component {...modalProps} {...props} />
            }

            return null
          }}
        </AuthProvider>
      )
    }}
  </Location>
)

export default MatchQueryModal
