import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Field } from 'react-final-form'

import Icon from 'components/Icon'

export const Checkbox = ({
  id,
  name,
  label,
  renderLabel,
  className,
  style,
  value,
  ...props
}) => (
  <CheckboxLabel htmlFor={id || name} className={className} style={style}>
    <CheckboxInput
      type='checkbox'
      id={id || name}
      name={name}
      checked={value}
      {...props}
    />
    <CheckmarkIcon />

    {label && <span>{label}</span>}

    {renderLabel && renderLabel(props)}
  </CheckboxLabel>
)

const CheckboxField = ({
  id,
  name,
  label,
  renderLabel,
  className,
  style,
  ...props
}) => (
  <Field type='checkbox' name={name} {...props}>
    {({ input }) => (
      <Checkbox
        {...{
          id,
          name,
          label,
          renderLabel,
          className,
          style,
          ...input,
          ...props
        }}
      />
    )}
  </Field>
)

export const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: ${colors.blackRGBA(0.5)};

  > span {
    margin-left: 0.5rem;
  }
`

export const CheckboxInput = styled.input`
  display: none;

  &:checked ~ svg {
    border: 2px solid ${colors.darkBlue};
    background-color: ${colors.darkBlue};
  }

  &:checked:disabled ~ svg {
    background-color: ${colors.lightBlue};
    border-color: ${colors.lightBlue};
  }

  &:checked ~ span {
    color: ${colors.blackRGBA(0.7)};
  }
`

export const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmark-i' })`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  border-radius: 30px;
  color: #fff;
  border: 2px solid ${colors.blackRGBA(0.3)};
  transition: all 0.25s ease;
`

export default CheckboxField
