import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'

import { Provider } from 'unstated'

import 'sanitize.css'
import 'tachyons'
import 'typeface-lato'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'emoji-mart/css/emoji-mart.css'

import { createGlobalStyle } from 'styled-components'

import colors from 'styles/colors'
import logger from 'utils/logger'

import Header from './Header'
import AuthQueryModals from './AuthQueryModals'
import TooltipsProvider from './TooltipsProvider'
// import FeedbackWidget from './FeedbackWidget'

const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: auto;
  }

  img {
    margin: 0;
  }

  *:active {
    outline: 0;
  }

  .link.outline-0:focus {
    outline: 0;
  }

  .scrollbar {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: ${colors.blackRGBA(0.2)};

      &:hover {
        background-color: ${colors.blackRGBA(0.3)};
      }

      &:active {
        background-color: ${colors.blackRGBA(0.4)};
      }
    }
  }
`

class Layout extends Component {
  componentDidCatch (error, errorInfo) {
    logger.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    logger.captureException(error)
  }

  render () {
    const { pageTitle, children, className, style } = this.props

    return (
      <Provider>
        <Fragment>
          <Helmet
            titleTemplate='%s - StayCircles'
            defaultTitle='StayCircles - Stay with each other'
            meta={[
              {
                name: 'description',
                content:
                  'Book rooms with friends and friends-of-friends. Stay with friends for fun - or business travel where everybody wins.'
              },
              {
                name: 'keywords',
                content: 'business travel, business stay, friends'
              }
            ]}
          >
            <html lang='en' />

            {pageTitle && <title>{pageTitle}</title>}
          </Helmet>

          <Header />

          <div className={className} style={style}>
            <TooltipsProvider />

            {children}

            <AuthQueryModals />
          </div>

          <GlobalStyle />

          {/* <FeedbackWidget /> */}
        </Fragment>
      </Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
