import React from 'react'

import navigateBack from 'utils/navigateBack'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Form } from 'react-final-form'

import { required } from 'utils/field-validators'

import SendResetPasswordStoreProvider from './store'

import Modal from 'components/Modal'
import Input, { ErrorText } from 'components/Input'
import Button from 'components/Button'
import OTPForm from 'components/OTP'

import { navigate } from 'gatsby'

const SendResetPasswordModal = () => (
  <Modal
    isOpen
    onRequestClose={navigateBack}
    contentLabel='Send Reset Password'
  >
    <SendResetPasswordStoreProvider>
      {store => (
        <Form
          onSubmit={(values, _, callback) => {
            store.transition({ type: 'SUBMIT', values, callback })
          }}
          render={({
            handleSubmit,
            submitting,
            submitSucceeded,
            invalid,
            submitError,
            values
          }) => {
            if (store.matchesActions('verifySuccess')) {
              navigate(`?reset_password_token=${store.state?.code}`)
            }
            if (submitSucceeded) {
              return (
                <OTPForm
                  handleSubmit={otp =>
                    // navigate(`?reset_password_token=${otp}`)
                    store.transition({ type: 'VERIFYOTP', code: otp })
                  }
                  invalid={store.matchesActions('showVerifyError')}
                  submitError={store.state?.error}
                  handleResend={() => {
                    store.transition({ type: 'RESEND', values })
                  }}
                  submitting={store.matchesActions('showVerifying')}
                  resending={store.matchesActions('showLoading')}
                />
                // <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                //   <h1 className='tc mt0'>Thank you</h1>

              //   <p
              //     className='tc lh-copy w-80-ns'
              //     style={{ color: colors.blackRGBA(0.7) }}
              //   >
              //     If your email is valid, you'll receive an email with
              //     instructions on how to reset your password.
              //   </p>
              // </div>
              )
            }

            return (
              <form
                onSubmit={handleSubmit}
                className='center flex flex-column items-center w-100 w-90-ns mv4'
              >
                <h1 className='tc mt0'>What's your email?</h1>

                <p
                  className='tc mb4 lh-copy w-80-ns'
                  style={{ color: colors.blackRGBA(0.7) }}
                >
                  Please verify your email for us. Once you do, we'll send
                  verification code to reset your password.
                </p>

                <Input
                  id='email'
                  name='email'
                  label='Email address'
                  autoComplete='email'
                  validate={required}
                  disabled={submitting}
                />

                {invalid && submitError && (
                  <ErrorText center style={{ marginBottom: '2rem' }}>
                    {submitError}
                  </ErrorText>
                )}

                <SubmitButton
                  type='submit'
                  color={colors.white}
                  colorHover={colors.offwhite}
                  bgColor={colors.red}
                  bgColorHover={colors.darkRed}
                  disabled={submitting}
                  expand
                  expandWidth='100%'
                >
                  {submitting ? 'LOADING...' : 'RESET MY PASSWORD'}
                </SubmitButton>
              </form>
            )
          }}
        />
      )}
    </SendResetPasswordStoreProvider>
  </Modal>
)

const SubmitButton = styled(Button)`
  letter-spacing: 2px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

export default SendResetPasswordModal
