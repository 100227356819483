import api from 'utils/api'

export const signUpEmail = email => {
  const data = {
    email: formatEmail(email)
  }

  return api.get('/auth/send_signup_confirmation', data)
}

/**
 * Helpers
 */

function formatEmail (email) {
  return email.trim().toLowerCase()
}
