import api from 'utils/api'

export const checkSignupToken = token => {
  return api.get('/auth/signup_token_enquiry', { token })
}

export const checkInvitationToken = token => {
  return api.get('/auth/invitation_token_enquiry', { token })
}

export const createAccount = values => {
  const data = {
    user: {
      passwordConfirmation: values.password,
      agreementAccepted: true,
      rememberMe: true,
      ...values
    }
  }

  return api.post('/auth/sign_up', data)
}
