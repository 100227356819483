import React from 'react'

import ReactModal from 'react-modal'

import styled from 'styled-components'

import colors from 'styles/colors'
import zIndex from 'styles/zIndex'

ReactModal.setAppElement('#___gatsby')

const Modal = props => (
  <ModalComponent
    style={{
      overlay: {
        backgroundColor: colors.blackRGBA(0.5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: zIndex.modal,
        overflowX: 'auto'
      }
    }}
    {...props}
  />
)

const ModalComponent = styled(ReactModal)`
  margin-top: 70px;
  min-width: 240px;
  width: 90%;
  max-width: 520px;
  padding: 2rem;
  background-color: ${props => props.backgroundColor || '#fff'};
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    width: 96%;
  }
`

export default Modal
