import React from 'react'

import ReactHintFactory from 'react-hint'
import styled from 'styled-components'

import 'react-hint/css/index.css'

const ReactHint = ReactHintFactory(React)

class Tooltip extends React.PureComponent {
  render () {
    const { content, className } = this.props

    return <p className={`react-hint__content ${className}`}>{content}</p>
  }
}

class TooltipsProvider extends React.Component {
  shouldComponentUpdate () {
    return false
  }

  _onRenderContent (target, content) {
    return <StyledTooltip target={target} content={content} />
  }

  render () {
    return (
      <ReactHint
        autoPosition
        events={{ hover: true }}
        delay={150}
        onRenderContent={this._onRenderContent}
      />
    )
  }
}

const StyledTooltip = styled(Tooltip)`
  font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  font-size: 12px;
  max-width: 200px;
  line-height: 1.35;
  text-align: center;
`

export default TooltipsProvider
