import React from 'react'
import PropTypes from 'prop-types'
import { Container, Subscribe } from 'unstated'
import { Machine } from 'xstate'

/**
 * `StateMachineContainer` is used by `<StateMachineProvider />` to give it
 * suppport for a state machine in a _unstated_ fashion.
 *
 * It is decoupled from `<StateMachineProvider />` so it can be extended.
 * Extend it like you'd do with a unstated Container and give the extended
 * containter instance to the provider and it will use the extended container's
 * methods as the statechart transition actions (if they match any action).
 * The store provided by the StateMachineProvider will contain the
 * stateMachineStore and also whatever state and instance methods you put into
 * the extended container, just like a normal unstated Container.
 *
 * Example of usage see [react-automate Action methods](https://github.com/MicheleBertoli/react-automata#action-methods)
 */
export class StateMachineContainer extends Container {
  constructor (props = {}) {
    super(props)

    if (!this.machine && !props.statechart) {
      throw new Error('StateMachineContainer requires a statechart')
    }

    this.machine = this.machine || Machine(props.statechart)

    this.state = {
      machine: this.machine,
      machineState: this.machine.initialState
    }
  }

  setTransitionHandler = handler => {
    this.transition = handler || this.handleTransition
  }

  handleTransition = async (event, extendedState) => {
    await this.setState(prevState => {
      const nextState = this.machine.transition(
        prevState.machineState,
        event,
        extendedState
      )

      return {
        machineState: nextState,
        event
      }
    })

    return this.state.machineState
  }

  matchesActions = value => {
    const { actions } = this.state.machineState

    return Array.isArray(value)
      ? actions.some(action => value.includes(action))
      : actions.includes(value)
  }
}

const StateMachineContext = React.createContext({})

/**
 * <StateMachineProvider statechart={statechart} />
 *
 * @statechart Required if `container` is not given. A statechart allows
 * StateMachineProvider to pass down its children a stateMachineStore
 * (using unstated) with the machineState and transition func. It works
 * similarly to [react-automata](https://github.com/MicheleBertoli/react-automata).
 *
 * Lifecycle hooks `componentWillTransition(event)` and
 * `componentDidTransition(prevStateMachine, event)` also work.

 * @instanceRef Use instanceRef prop with a component reference and it will
 * run the statechart transition actions based on the component's methods.
 *
 * @actions Alias for `instanceRef`.
 *
 * Note: instanceRef can also be `this` of a component or even a simple object of actions.
 *
 * @container Required if `statechart` is not given. Use container prop
 * with a modified instance of StateMachineContainer. Don't provide instanceRef
 * and it will run the statechart transition actions using this container's methods.
 * Useful for extending `StateMachineContainer` to use it as a normal unstated
 * store and API calls.
 *
 * @renderOnAction Define an action (or set of actions) to receive a second
 * argument that points when it is active on the statechart. Useful for
 * simple statecharts when you just want to use the provider to show/hide
 * certain components based on a specific action or specific set of actions.
 * For complex component trees and statecharts use `<RenderOnAction />` instead.
 *
 * @children This is a children as a function prop. Receives a stateMachineStore
 * as argument. If `renderOnAction` is defined it passes `visible`, which
 * matches active statechart actions, as second argument.
 */
export default class StateMachineProvider extends React.Component {
  static propTypes = {
    statechart: PropTypes.object,
    instanceRef: PropTypes.object,
    actions: PropTypes.object,
    container: PropTypes.instanceOf(StateMachineContainer),
    renderOnAction: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string
    ]),
    children: PropTypes.func
  }

  constructor (props = {}) {
    super(props)

    this.container =
      this.container ||
      props.container ||
      new StateMachineContainer({ statechart: props.statechart })

    const transitionHandler = this.handleTransition
    this.container.setTransitionHandler(transitionHandler)

    const instance = props.instanceRef || props.actions || this.container
    this.setInstance(instance)
  }

  componentDidMount () {
    this.runActionMethods()
  }

  setInstance = instance => {
    this.instance = instance
  }

  runActionMethods () {
    if (this.instance) {
      this.container.state.machineState.actions.forEach(action => {
        if (this.instance[action]) {
          const event = this.container.state.event
          const transition = this.container.transition
          this.instance[action](event, transition)
        }
      })
    }
  }

  handleTransition = async (event, extendedState) => {
    if (this.instance && this.instance.componentWillTransition) {
      this.instance.componentWillTransition(event)
    }

    const prevMachineState = this.container.state.machineState

    const machineState = await this.container.handleTransition(
      event,
      extendedState
    )

    if (prevMachineState.actions !== machineState.actions) {
      this.runActionMethods()
    }

    if (prevMachineState !== machineState) {
      if (this.instance && this.instance.componentDidTransition) {
        this.instance.componentDidTransition(prevMachineState, event)
      }
    }

    return machineState
  }

  renderChildren = machineStore => {
    if (this.props.renderOnAction) {
      const action = this.props.renderOnAction
      const visible = machineStore.matchesActions(action)

      return this.props.children(machineStore, visible)
    }

    return this.props.children(machineStore)
  }

  render () {
    return (
      <Subscribe to={[this.container]}>
        {machineStore => (
          <StateMachineContext.Provider value={machineStore}>
            {this.renderChildren(machineStore)}
          </StateMachineContext.Provider>
        )}
      </Subscribe>
    )
  }
}

/**
 * In case you need to use StateMachineProvider as a HOC.
 *
 * @statechart Required if container is undefined
 * @container Required if statechart is undefined
 */
export const withStateMachine = (statechart, container) => Component => {
  return class StateMachineProviderWrapper extends React.Component {
    render () {
      return (
        <StateMachineProvider statechart={statechart} container={container}>
          {machineStore => (
            <Component {...this.props} machineStore={machineStore} />
          )}
        </StateMachineProvider>
      )
    }
  }
}

/**
 * The component to define which parts of the tree should be rendered for a
 * given action (or set of actions). Must be within the tree of a StateMachineProvier.
 *
 * @value An action as String or Array of multiple actions
 * @children Children as a function. Receives `visible` as true when action(s)
 * given matches a current action of the parent state machine.
 */
export const RenderOnAction = ({ value, children }) => (
  <StateMachineContext.Consumer>
    {machineStore => {
      const visible = machineStore.matchesActions(value)

      if (typeof children === 'function') {
        return children(visible)
      }

      return visible ? children : null
    }}
  </StateMachineContext.Consumer>
)

/**
 * Same as RenderOnAction but returns to children function as many args as there are actions
 */
export const RenderOnActions = ({ values = [], children }) => (
  <StateMachineContext.Consumer>
    {machineStore => {
      const visible = values.map(machineStore.matchesActions)

      if (typeof children === 'function') {
        return children(...visible)
      }

      return visible.some(v => !v) ? null : children
    }}
  </StateMachineContext.Consumer>
)

RenderOnAction.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}
