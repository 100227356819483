import luhnChk from './luhnChk'
import brnv from 'bank-routing-number-validator'

export const required = value => (value ? undefined : 'Required')

export const mustBeNumber = value =>
  (isNaN(value) ? 'Must be a number' : undefined)

export const minValue = min => value =>
  (isNaN(value) || value >= min ? undefined : `Should be ${min} or higher`)

export const maxValue = max => value =>
  (isNaN(value) || value <= max ? undefined : `Should be ${max} or lower`)

export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues),
    undefined
  )

export const validateEmail = value =>
  (/\S+@\S+\.\S+/.test(value) ? undefined : 'Invalid Email Address')

export const minLength = min => value =>
  (String(value).length >= min
    ? undefined
    : `Should be ${min} characters or more`)

export const maxLength = max => value =>
  (String(value).length <= max
    ? undefined
    : `Should be ${max} characters or less`)

export const exactLength = length => value =>
  (String(value).length === length
    ? undefined
    : `Should be ${length} characters`)

export const validateZipCode = value => {
  const valid = value.length >= 5 && value.length <= 10

  return valid ? undefined : 'Enter a valid zip code'
}

export const validateCreditCard = value =>
  (luhnChk(value) ? undefined : 'Invalid number')

export const validateAccountNumber = value => {
  const number = value && value.toString().replace(/\D/g, '')
  return number && number.length <= 17 ? undefined : 'Invalid account number'
}

export const validateRoutingNumber = value => {
  return brnv.ABARoutingNumberIsValid(value)
    ? undefined
    : 'Invalid routing number'
}
