import cloudinaryCore from 'cloudinary-tiny-js'
import { get, has, isUndefined } from 'lodash'

import camelize from './camelize'

import config from 'config'

import root from 'utils/windowOrGlobal'
import colors from 'styles/colors'

export const cloudinary = cloudinaryCore({
  cloudName: config.CLOUDINARY_CLOUDNAME,
  secure: true,
  defaults: {
    fetchFormat: 'auto',
    quality: 'auto'
  }
})

export function getFullName (user) {
  if (!user) return

  user = camelize(user)

  if (get(user, 'firstName') || get(user, 'lastName')) {
    return `${user.firstName || ''} ${user.lastName || ''}`.trim()
  }

  return get(user, 'name')
}

const avatarCloudinaryConfig = config => {
  if (!config || !config.transform) {
    config = config || {}
    config = {
      quality: 'auto:best',
      fetchFormat: 'auto',
      crop: 'scale',
      angle: 'ignore',
      flags: 'preserve_transparency',
      radius: 'max',
      width: 60,
      ...config
    }
  }
  return config
}

export const getDefaultPicture = (user, config = {}) => {
  const defaultConfig = avatarCloudinaryConfig(config)

  const avatarStyle = {
    effect: 'colorize:100',
    opacity: 45
  }

  const friend = isFriend(user)

  if (isUndefined(friend)) {
    config = Object.assign({}, defaultConfig, avatarStyle, config)

    return cloudinary('assets/avatar-person.png', config)
  }

  if (friend) {
    avatarStyle.color = colors.red
  } else {
    avatarStyle.color = colors.blue
  }

  config = Object.assign({}, defaultConfig, avatarStyle, config)

  return cloudinary('assets/avatar-person.png', config)
}

export const getCloudinaryPicture = (user, config) => {
  user = camelize(user)

  if (user.cloudinaryPublicId) {
    config = avatarCloudinaryConfig(config)
    return cloudinary(user.cloudinaryPublicId, config)
  }

  return user.cloudinaryImgUrl
}

export const getUserPicture = (user, config = {}) => {
  user = camelize(user)

  return (
    (user &&
      (getCloudinaryPicture(user, config) ||
        user.picture ||
        user.profilePicture ||
        user.pictureUrl)) ||
    getDefaultPicture(user, config)
  )
}

export const isDefaultPicture = picOrUser => {
  let pic = picOrUser
  if (typeof picOrUser === 'object' && picOrUser.id) {
    pic = getUserPicture(picOrUser)
  }

  return /default/.test(pic)
}

export const getPlaceAddress = (
  place,
  includeZip = true,
  includeAddress2 = true
) => {
  let clonedPlace = { ...place }

  let addressElements = ['address1', 'address_1']

  if (includeAddress2) {
    addressElements.push('address2', 'address_2')
  }

  addressElements.push('city', 'state')

  if (includeZip) {
    addressElements.push('zip_code')
  }

  addressElements.push('country')

  let fullAddress = []
  let firstLine = []
  let secondLine = []

  addressElements.forEach(key => {
    clonedPlace[key] = clonedPlace[key] || ''
    fullAddress.push(clonedPlace[key])

    if (['address1', 'address_1', 'address2', 'address_2'].includes(key)) {
      firstLine.push(clonedPlace[key])
    } else {
      secondLine.push(clonedPlace[key])
    }
  })

  const getAddressString = (addressArray, commaOnCity = true) => {
    return addressArray
      .filter(el => !!el)
      .map(el => {
        if (
          clonedPlace['address2'] === el ||
          (commaOnCity && clonedPlace['city'] === el) ||
          clonedPlace['state'] === el ||
          (!clonedPlace['zip_code'] && clonedPlace['country'] === el)
        ) {
          return `, ${el}`
        }
        return ` ${el}`
      })
      .join('')
  }

  return {
    ...clonedPlace,
    fullAddress: getAddressString(fullAddress),
    firstLine: getAddressString(firstLine),
    secondLine: getAddressString(secondLine, false)
  }
}

export const isPlaceAddressPublic = place => {
  const friend = isFriend(place.owner) || isCurrentUser(place.owner)
  return friend && get(place, 'owner.is_address_public')
}

export const isCurrentUser = (sp, current) => {
  if (current) {
    return sp.id === current.id
  }

  return sp && Number(sp.connection) === 0
}

export const isFriend = (sp = {}) => {
  sp = camelize(sp)

  const keysMap = {
    friend: true,
    connection: 1,
    connectionType: 1,
    connectionValue: 3
  }

  if (Object.keys(keysMap).some(k => has(sp, k))) {
    return Object.keys(keysMap).some(k => get(sp, k) === keysMap[k])
  }
}

export const getStayPalRequestStatus = spOrValue => {
  if (!spOrValue) return null

  const value = spOrValue.connectionValue
    ? Number(spOrValue.connectionValue)
    : Number(spOrValue)

  switch (value) {
    case 0:
      return 'current'
    case 1:
      return 'pending'
    case 2:
      return 'received'
    case 3:
      return 'approved'
    default:
      return null
  }
}

const OUTDATED_BROWSER_MSG =
  "Your browser is outdated. For a better experience update your browser to its newest version or if you're on mobile switch to a computer to proceed."

export const createBlobURL = file => {
  const URL = window.URL || window.webkitURL
  if (!URL || !URL.createObjectURL) {
    root.alert(OUTDATED_BROWSER_MSG)
    throw new Error('window.URL was not supported')
  }

  const url = URL.createObjectURL(file)
  const clearURL = () => {
    URL.revokeObjectURL(url)
  }

  return { url, clearURL }
}
