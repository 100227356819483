import React from 'react'

import { navigate, Location } from '@reach/router'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'

import * as routes from 'config/routes'

import navigateBack from 'utils/navigateBack'
import { getUrlParameter } from 'utils/parse-querystring'

import styled from 'styled-components'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Loader from 'components/Loader'

import UnsubscribeStoreProvider from './store'

const MESSAGES = {
  stay_proposal:
    'when you receive a Stay Proposal from a friend or friend of friend',
  stay_accepted:
    'when a stay proposal you have sent is accepted by your friend/host.',
  stay_decline:
    'when a stay proposal you have sent is declined by your Friends or Friends-of-Friends.',
  stay_canceled:
    'when a confirmed stay you are either the traveler or host of is canceled.',
  sp_request: 'when you receive a Friend Request. ',
  sc_invitation_acceptance:
    "when a StayCircles Invitation you've sent to a friend has been accepted.",
  sp_invitation_acceptance: 'when your Friend Request is accepted.',
  sc_new_features_and_updates:
    'when new features and updates are added to StayCircles.',
  sc_app_reminders:
    'when StayCircles needs to remind you about your profile or account.',
  economy_flight_deals: 'for Adventure Machine Economy Flight Deals.',
  first_or_business_class_flight_deals:
    'for Adventure Machine First or Business Class Flight Deals.',
  prime_or_prime_first_hidden_flight_deals:
    'for Adventure Machine Prime or Prime First Hidden Flight Deals.',
  scly_unread: 'when you receive a feedback chat message',
  unread_message_from_sp: 'Unread message from a Friends or Friends-of-Friends',
  sc_intro_email_series: 'from StayCircles Introduction Email Series'
}

const UnsubscribeModal = () => (
  <Modal isOpen onRequestClose={navigateBack} contentLabel='Unsubscribe'>
    <Location>
      {({ location }) => (
        <UnsubscribeStoreProvider location={location}>
          {store => {
            const category = getUrlParameter(location, 'category')
            const type =
              getUrlParameter(location, 'unsubscribe_type') ||
              getUrlParameter(location, 'preference_type')
            const email = getUrlParameter(location, 'id')

            const loading = store.matchesActions('showLoading')
            const subscribed = get(store.state.preferences, [
              camelCase(category),
              camelCase(type)
            ])

            return (
              <div className='bg-white ph4 br4 f5 lh-copy tc'>
                <p className='mb4'>
                  It happened. It hurts. But... we still think you’re great.
                </p>

                <p className='f3-ns mb4'>
                  You've been{' '}
                  <span style={{ color: colors.red }}>unsubscribed</span> from
                  email notifications {MESSAGES[type] || ''}
                </p>

                {!email && (
                  <div>
                    <p>
                      Ooops...{' '}
                      <span style={{ color: colors.blue }}>
                        maybe this was a mistake?
                      </span>{' '}
                      It happens to the best of us...
                    </p>

                    <p className='mt3'>Click here to:</p>

                    <SubscribeButton
                      loading={loading}
                      onClick={() =>
                        store.transition({ type: 'UNSUBSCRIBE', status: true })
                      }
                      disabled={subscribed}
                      children={subscribed ? 'Subscribed' : 'Resubscribe'}
                    />

                    <ExtremeLinks
                      className='dn-ns'
                      left
                      color='red'
                      children='StayPals'
                      onClick={() => {
                        navigate(routes.APP + '?activePanel=myStayPals')
                      }}
                    />

                    <ExtremeLinks
                      className='dn-ns'
                      right
                      color='yellow'
                      children='Map'
                      onClick={() => navigate(routes.APP)}
                    />
                  </div>
                )}

                {email && (
                  <div>
                    <SubscribeButton
                      loading={loading}
                      disabled
                      children='Unsubscribed'
                    />
                  </div>
                )}
              </div>
            )
          }}
        </UnsubscribeStoreProvider>
      )}
    </Location>
  </Modal>
)

const LoaderContainer = () => (
  <div className='h3 m0 tc'>
    <Loader width='25px' height='35px' />
  </div>
)

const SubscribeButton = ({ loading, ...props }) => {
  if (loading) return <LoaderContainer />

  return <SubmitButton className='mv3' {...props} />
}

const SubmitButton = styled(Button)`
  font-size: 18px;
  font-weight: 400;
  padding: 18px 24px;
  border-radius: 10px;
  ${props =>
    props.disabled
      ? `
    color: ${colors.white};
    background-color: ${colors.blueRGBA(0.7)};
  `
      : ''};
`

const ExtremeLinks = styled.button.attrs({ type: 'buttton' })`
  padding: 8px 12px;
  position: absolute;
  bottom: 0;
  border-width: 0;
  color: ${colors.white};
  background-color: ${props => colors[props.color] || colors.blue};

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.left
      ? `
    border-top-right-radius: 10px;
    left: 0;
    `
      : `
    border-top-left-radius: 10px;
    right: 0;
    `};
`

export default UnsubscribeModal
