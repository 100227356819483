import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Form } from 'react-final-form'

import { required } from 'utils/field-validators'

import * as routes from 'config/routes'

import { navigate } from 'gatsby'

import SignUpStoreProvider from './store'

// import Icon from 'components/Icon'
import Input, { ErrorText } from 'components/Input'
import Button from 'components/Button'
import OTPForm from 'components/OTP'

// const isGmail = email => email && !!email.match(/gmail/g)

const SignUpModal = () => (
  <SignUpStoreProvider>
    {store => (
      <Form
        initialValues={{ remember: true }}
        onSubmit={(values, _, callback) => {
          store.transition({ type: 'SUBMIT', values, callback })
        }}
        render={({
          handleSubmit,
          submitting,
          invalid,
          submitError,
          submitSucceeded,
          values
        }) => {
          if (store.matchesActions('verifySuccess')) {
            navigate(
              `${routes.CREATE_ACCOUNT}?confirmation_code=${store.state?.code}`
            )
          }
          if (submitSucceeded) {
            return (
              <OTPForm
                handleSubmit={otp =>
                  // navigate(`${routes.CREATE_ACCOUNT}?confirmation_code=${otp}`)
                  store.transition({ type: 'VERIFYOTP', code: otp })
                }
                invalid={store.matchesActions('showVerifyError')}
                submitError={store.state?.error}
                handleResend={() => {
                  store.transition({ type: 'RESEND', values })
                }}
                submitting={store.matchesActions('showVerifying')}
                resending={store.matchesActions('showLoading')}
              />
              // <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
              //   <CheckMark className='mb4' />

            //   <h1 className='tc mt0 lh-title'>Confirm your email</h1>

            //   <p
            //     className='tc mb4 lh-copy'
            //     style={{ color: colors.blackRGBA(0.7) }}
            //   >
            //     We’ve sent you a confirmation email at
            //     <b> {values.email}</b>.<br />
            //     Open it now to confirm your email address.
            //   </p>

            //   {isGmail(values.email) && (
            //     <a
            //       href='https://mail.google.com/mail/u/0/#search/subject%3A%22StayCircles%22'
            //       target='_blank'
            //       rel='noreferrer'
            //       className='link black dim'
            //     >
            //       <Icon name='message' height={24} className='mr2' />
            //       Easy-open in Gmail
            //     </a>
            //   )}
            // </div>
            )
          }

          return (
            <form
              onSubmit={handleSubmit}
              className='center flex flex-column items-center w-100 w-90-ns mb0'
            >
              <Input
                id='email'
                name='email'
                label='Your email address'
                autoComplete='email'
                validate={required}
                disabled={submitting}
              />

              {invalid && submitError && (
                <ErrorText center style={{ marginBottom: '2rem' }}>
                  {submitError}
                </ErrorText>
              )}

              <SubmitButton
                className='mb3'
                type='submit'
                color={colors.darkBlueII}
                colorHover={colors.lightBlue}
                bgColor={colors.lightBlueI}
                bgColorHover={colors.darkIce}
                disabled={submitting}
                expand
                expandWidth='100%'
              >
                {submitting ? 'SENDING...' : 'Confirm email'}
              </SubmitButton>
            </form>
          )
        }}
      />
    )}
  </SignUpStoreProvider>
)

const SubmitButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;

  @media (max-width: 480px) {
    width: 100%;
    font-size: 16px;
  }
`

// const CheckMark = styled(Icon).attrs({ name: 'checkmark' })`
//   color: ${colors.darkGreen};
//   height: 48px;
// `

export default SignUpModal
