import api from 'utils/api'
import config from 'config'

export const unsubscribe = ({ unsubscribeType, email, unsubscribeToken }) => {
  if (unsubscribeToken) {
    return api.post('/email_unsubscriptions/preference', {
      preferenceType: unsubscribeType,
      uToken: unsubscribeToken
    })
  }

  const data = { type: unsubscribeType, id: email }

  return api.post('/email_unsubscriptions', data)
}

export const getNotificationPreferences = () => {
  return api.get('/preferences')
}

export const setNotificationPreference = ({ category, prefKey, checked }) => {
  const data = {
    preferences: {
      [category]: {
        [prefKey]: checked
      }
    }
  }

  return api.post('/preferences', data)
}

export const setSCLYPreference = subscribe => {
  const baseURL =
    config.ENV === 'production'
      ? 'https://scly.staycircles.com'
      : 'https://scly-staging.staycircles.com/'

  return api.get(
    '/subscribe-email',
    { subscribe },
    {
      baseURL
    }
  )
}
