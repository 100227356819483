import React, { useState } from 'react'
import Input, { ErrorText } from 'components/Input'
import colors from 'styles/colors'
import styled from 'styled-components'
import Button from 'components/Button'

const OTPForm = ({
  submitting,
  invalid,
  submitError,
  handleSubmit,
  resending,
  handleResend
}) => {
  const [otp, setOtp] = useState('')
  return (
    <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
      <h1 className='tc mt0'>Verification</h1>

      <p
        className='tc mb4 lh-copy w-80-ns outline-0'
        style={{ color: colors.blackRGBA(0.7) }}
      >
        Enter the verfication code we just sent you on your email address.
      </p>
      <Input
        id='otp'
        name='otp'
        label='Verification Code'
        type='number'
        onChange={e => setOtp(e.target.value)}
        disabled={submitting}
      />
      {invalid && submitError && (
        <ErrorText center style={{ marginBottom: '2rem' }}>
          {submitError}
        </ErrorText>
      )}
      <SubmitButton
        className='mb3'
        type='submit'
        color={colors.darkBlueII}
        colorHover={colors.lightBlue}
        bgColor={colors.lightBlueI}
        bgColorHover={colors.darkIce}
        disabled={submitting}
        expand
        expandWidth='100%'
        onClick={() => handleSubmit(otp)}
      >
        {submitting ? 'Verifying...' : 'Verify'}
      </SubmitButton>
      <Button link onClick={handleResend} disabled={resending}>
        {resending ? 'Resending code...' : 'Resend'}
      </Button>
    </div>
  )
}

const SubmitButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;

  @media (max-width: 480px) {
    width: 100%;
    font-size: 16px;
  }
`

export default OTPForm
