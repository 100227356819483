import PropTypes from 'prop-types'

import styled from 'styled-components'

import colors from 'styles/colors'

const styles = {
  background: {
    default: props =>
      props.bgColor ||
      (props.primary
        ? colors.darkBlue
        : props.link
          ? 'transparent'
          : colors.white),
    hover: props =>
      props.bgColorHover ||
      (props.primary
        ? colors.blue
        : props.link
          ? 'transparent'
          : colors.offwhite),
    disabled: props => (props.link ? 'transparent' : colors.blackRGBA(0.4))
  },
  color: {
    default: props =>
      props.color ||
      (props.primary
        ? colors.white
        : props.light
          ? colors.blue
          : colors.darkBlue),
    hover: props =>
      props.colorHover ||
      (props.primary
        ? colors.white
        : props.light
          ? colors.darkBlue
          : colors.blue),
    disabled: props => (props.primary ? colors.white : colors.blackRGBA(0.7))
  },
  border: {
    default: props =>
      props.bgColor ||
      (props.link
        ? 'transparent'
        : props.light
          ? colors.blue
          : colors.darkBlue),
    hover: props =>
      props.bgColorHover ||
      (props.link ? 'none' : props.light ? colors.darkBlue : colors.blue),
    disabled: props => (props.light ? colors.blackRGBA(0.7) : 'none')
  },
  width: {
    default: props =>
      props.width ? props.width : props.expand ? props.expandWidth : 'auto',
    mobile: props =>
      props.expandOnMobile || props.expand
        ? props.expandWidth
        : props.width
          ? props.width
          : 'auto'
  },
  height: {
    default: props => (props.height ? props.height : 'auto')
  },
  fontWeight: {
    default: props => (props.link ? 500 : 600)
  }
}

const Button = styled.button`
  border-style: solid;
  border-width: 1px;
  border-color: ${props =>
    props.disabled
      ? styles.border.disabled(props)
      : styles.border.default(props)};
  border-radius: 6px;
  padding: 21px ${props => (props.link ? '12px' : '32px')};
  font-size: 16px;
  font-weight: ${styles.fontWeight.default};
  background-color: ${props =>
    props.disabled
      ? styles.background.disabled(props)
      : styles.background.default(props)};
  color: ${props =>
    props.disabled
      ? styles.color.disabled(props)
      : styles.color.default(props)};
  width: ${styles.width.default};
  height: ${styles.height.default};
  line-height: ${props => (props.height ? props.height : '1')};
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${props =>
    props.disabled
      ? styles.border.disabled(props)
      : styles.border.hover(props)};
    background-color: ${props =>
    props.disabled
      ? styles.background.disabled(props)
      : styles.background.hover(props)};
    color: ${props =>
    props.disabled
      ? styles.color.disabled(props)
      : styles.color.hover(props)};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    text-decoration: ${props => (props.link ? 'underline' : 'none')};
  }

  &:focus {
    outline: thin dotted;
  }

  @media (max-width: 480px) {
    width: ${styles.width.mobile};
  }
`

Button.defaultProps = {
  type: 'button',
  expandWidth: '90%'
}

Button.propTypes = {
  type: PropTypes.string,
  expand: PropTypes.bool,
  expandOnMobile: PropTypes.bool,
  expandWidth: PropTypes.string,
  primary: PropTypes.bool,
  link: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  bgColorHover: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
}

export default Button
