import React from 'react'
import styled from 'styled-components'
import { useNavigate } from '@reach/router'
// import { useGoogleLogin } from '@react-oauth/google'
import { getGoogleOauthPromptScreen } from './api'
import Icon from 'components/Icon'
import logger from 'utils/logger'

const GoogleSignInButton = () => {
  const navigate = useNavigate()
  // const login = useGoogleLogin({
  //   onSuccess: async tokenResponse => {
  //     console.log(tokenResponse)
  //   },
  //   onError: errorResponse => console.log(errorResponse),
  //   scope:
  //     'email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
  // })

  const login = async () => {
    const [error, data] = await getGoogleOauthPromptScreen()
    if (error) {
      console.log('Error [googleOAuthPrompt]-----', error)
      logger.captureException(error)
      return
    }
    const { googleAuthUri } = data
    navigate(googleAuthUri)
  }

  return (
    <GoogleLoginButton onClick={() => login()}>
      <IconContainer>
        <GoogleIcon name='google' />
      </IconContainer>
      <TextContainer>
        <ButtonText>Sign in with Google</ButtonText>
      </TextContainer>
    </GoogleLoginButton>
  )
}

const GoogleLoginButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 0.5px solid #e0e0e0;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  width: 100%;
`

const IconContainer = styled.div`
  margin-right: 6px;
`

const GoogleIcon = styled(Icon)`
  width: 14px;
  height: 14px;
`

const TextContainer = styled.div``

const ButtonText = styled.div`
  font-size: 1em;
`

export default GoogleSignInButton
