import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { getUrlParameter } from 'utils/parse-querystring'

import logger from 'utils/logger'
import root from 'utils/windowOrGlobal'

import * as routes from 'config/routes'
import { isLoggedIn } from 'stores/auth'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import * as api from './api'

const statechart = {
  initial: 'loading',
  states: {
    idle: {
      on: {
        UNSUBSCRIBE: 'unsubscribing'
      }
    },
    loading: {
      onEntry: ['showLoading', 'handleLoad'],
      on: {
        SUCCESS: 'idle',
        FAILURE: 'error'
      }
    },
    unsubscribing: {
      onEntry: ['showLoading', 'handleSubscription'],
      on: {
        SUCCESS: 'success',
        FAILURE: 'error'
      }
    },
    error: {
      on: {
        LOAD: 'loading',
        UNSUBSCRIBE: 'unsubscribing'
      },
      onEntry: 'showError'
    },
    success: {
      onEntry: 'showSuccess'
    }
  }
}

class UnsubscribeContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    const { category, email, unsubscribeType, unsubscribeToken } = props

    this.state = {
      ...stateMachine,
      token: props.token,
      preferences: {},
      error: null,
      category,
      email,
      unsubscribeType,
      unsubscribeToken
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'FAILURE':
          this.setError(event)
          break
        case 'SUCCESS':
          this.setSuccess(event)
          break
      }
    }
  }

  setSuccess = event => {
    const preferences = Object.assign(
      {},
      this.state.preferences,
      event.preferences
    )

    this.setState({ preferences })
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }

    this.setState({ error })
  }

  request = async (apiName, data) => {
    logger.captureBreadcrumb({
      message: 'Unsubscribe.' + apiName,
      category: 'unsubscribe',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'FAILURE', error })
      return false
    }
  }

  loadPreferences = async () => {
    const data = await this.request('getNotificationPreferences')

    if (data) {
      logger.captureBreadcrumb({
        message: 'Unsubscribe.loadPreferences success',
        category: 'unsubscribe'
      })

      this.transition({
        type: 'SUCCESS',
        preferences: get(data, 'preferences')
      })
    }
  }

  unsubscribeNotification = async params => {
    const data = await this.request('unsubscribe', params)

    if (data) {
      logger.captureBreadcrumb({
        message: 'Unsubscribe.unsubscribeNotification success',
        category: 'unsubscribe',
        data: params
      })

      this.transition({ type: 'SUCCESS' })
    }
  }

  setPreference = async ({ category, notificationType, status }) => {
    const params = { category, prefKey: notificationType, checked: status }

    const data = await this.request('setNotificationPreference', params)

    if (data) {
      logger.captureBreadcrumb({
        message: 'Unsubscribe.setPreference success',
        category: 'unsubscribe',
        data: { category, notificationType, status }
      })

      this.transition({
        type: 'SUCCESS',
        preferences: get(data, 'preferences')
      })
    }
  }

  setSCLYPreference = async status => {
    const data = await this.request('setSCLYPreference', status)

    if (data) {
      logger.captureBreadcrumb({
        message: 'Unsubscribe.setSCLYPreference success',
        category: 'unsubscribe',
        data: { status }
      })

      this.transition({
        type: 'SUCCESS',
        preferences: {
          emailNotifications: {
            sclyUnread: status
          }
        }
      })
    }
  }

  handleSubscription = async ({ status = false } = {}) => {
    const { category, email, unsubscribeType, unsubscribeToken } = this.state

    if (email || unsubscribeToken) {
      await this.unsubscribeNotification({
        email,
        unsubscribeToken,
        unsubscribeType:
          unsubscribeType || getUrlParameter(root.location, 'preference_type')
      })
    } else if (unsubscribeType) {
      if (unsubscribeType.match(/scly_/)) {
        this.setSCLYPreference(status)
      } else if (category) {
        this.setPreference({
          category,
          notificationType: unsubscribeType,
          status
        })
      }
    }
  }

  handleLoad = async () => {
    this.handleSubscription({ status: false })
  }
}

export default class UnsubscribeStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    const { location } = props
    const unsubscribeType = getUrlParameter(location, 'unsubscribe_type')
    const category = getUrlParameter(location, 'category')
    const email = getUrlParameter(location, 'id')
    const unsubscribeToken = getUrlParameter(location, 'u_token')

    this.container = new UnsubscribeContainer({
      statechart,
      category,
      email,
      unsubscribeType,
      unsubscribeToken
    })
  }

  componentDidMount () {
    const { location } = this.props

    // Do nothing if email id  or token is present
    if (
      getUrlParameter(location, 'id') ||
      getUrlParameter(location, 'u_token')
    ) {
      return
    }

    // Hotfix to support old FD email link and no category
    const type = getUrlParameter(location, 'unsubscribe_type')
    const category = getUrlParameter(location, 'category')

    let newLocation

    if (type === 'am_flight_deals') {
      newLocation = '/?unsubscribe_type=send_fd_emails&category=am_preferences'
    } else if (!category) {
      newLocation = `/?unsubscribe_type=${type}&category=email_notifications`
    }

    // Open Login modal if not logged in
    if (!isLoggedIn()) {
      const next = newLocation
        ? encodeURIComponent(newLocation)
        : encodeURIComponent(`/${location.search}`)
      newLocation = `/${routes.LOGIN}&next=${next}`
    }

    if (newLocation) {
      root.location.href = newLocation
    }
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
