import React, { PureComponent } from 'react'

import styled from 'styled-components'

import posed from 'react-pose'

import { Field } from 'react-final-form'

import colors from 'styles/colors'

class Input extends PureComponent {
  static defaultProps = {
    fieldProps: {},
    containerProps: {},
    floatingLabel: true,
    type: 'text'
  }

  state = {
    focus: false
  }

  render () {
    let {
      name,
      validate,
      format,
      parse,
      fieldProps,
      label,
      type,
      placeholder,
      helperText,
      readOnly,
      error,
      InputComponent = TextInput,
      LabelComponent = TextLabel,
      ContainerComponent = Container,
      containerProps,
      style,
      floatingLabel,
      ...props
    } = this.props

    const { focus } = this.state

    const id = props.id || props.name

    const placeholderText = floatingLabel && label ? label : placeholder

    return (
      <Field {...{ name, validate, format, parse, ...fieldProps }}>
        {({ input, meta: { touched, error } }) => (
          <ContainerComponent
            style={style}
            floatingLabel={floatingLabel}
            {...containerProps}
          >
            {(!floatingLabel || !focus) && label && (
              <LabelComponent
                className={floatingLabel ? 'dn' : ''}
                htmlFor={id}
                error={touched && error}
                children={label}
              />
            )}

            <InputComponent
              {...{
                id,
                type,
                ...props,
                ...input,
                placeholder:
                  floatingLabel && focus ? undefined : placeholderText,
                editable: !readOnly,
                error: touched && error,
                onChange: e => {
                  props.onChange && props.onChange(e)
                  input.onChange && input.onChange(e)
                },
                onFocus: e => {
                  this.setState({ focus: true })
                  props.onFocus && props.onFocus(e)
                  input.onFocus && input.onFocus(e)
                },
                onBlur: e => {
                  this.setState({ focus: false })
                  props.onBlur && props.onBlur(e)
                  input.onBlur && input.onBlur(e)
                }
              }}
            />

            {floatingLabel && (focus || !!input.value) && (
              <FloatingLabel
                htmlFor={id}
                initialPose='hidden'
                pose='visible'
                focus={focus}
              >
                {placeholderText}
              </FloatingLabel>
            )}

            {!(touched && error) && helperText && (
              <TextHelper>{helperText}</TextHelper>
            )}

            {touched && error && <ErrorText children={error} />}
          </ContainerComponent>
        )}
      </Field>
    )
  }
}

export const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${props => (props.floatingLabel ? '5px' : 0)};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

export const TextLabel = styled.label`
  font-size: 1rem;
  color: ${props => (props.error ? colors.red : colors.blackRGBA(0.6))};
  margin-top: 10px;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
`

const FloatingLabelPosed = posed.label({
  visible: { opacity: 1, delay: 50 },
  hidden: { opacity: 0, delay: 50 }
})

export const FloatingLabel = styled(FloatingLabelPosed)`
  font-size: 14px;
  line-height: 1.15;
  color: ${props =>
    props.focus
      ? colors.blue
      : props.error
        ? colors.red
        : colors.blackRGBA(0.6)};
  background-color: #fff;
  position: absolute;
  top: -3px;
  left: 0.75rem;
`

export const TextHelper = styled.span`
  font-size: 14px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  color: ${props => (props.error ? colors.red : colors.blackRGBA(0.5))};
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`
export const TextInput = styled.input`
  height: ${props => (props.small ? 36 : 56)}px;
  font-size: ${props => (props.small ? 0.875 : 1.125)}rem;
  padding: 0 1rem;
  color: ${colors.blackRGBA(0.9)};
  background-color: ${props =>
    props.disabled ? colors.blackRGBA(0.01) : colors.white};
  border-radius: 6px;
  border-width: 1px;
  border-color: ${props => (props.error ? colors.red : colors.blackRGBA(0.2))};

  &:hover {
    border-color: ${props =>
    props.error ? colors.darkRed : colors.blackRGBA(0.3)};
  }

  &:focus {
    outline: 0;
    border-color: ${colors.blue};
  }

  &::placeholder {
    opacity: 1;
    color: ${colors.blackRGBA(0.4)};
  }
`

TextInput.defaultProps = {
  type: 'text'
}

export const ErrorText = styled.span`
  font-size: 1rem;
  color: ${colors.red};
  text-align: ${props => (props.center ? 'center' : 'left')};
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`

export default Input
