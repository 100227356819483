import api from 'utils/api'

export const requestResetPassword = ({ email }) => {
  const data = {
    email: formatEmail(email)
  }

  return api.get('/auth/request_reset_password/', data)
}

/**
 * Helpers
 */

function formatEmail (email) {
  return email.trim().toLowerCase()
}
