/**
 * As an attempt to keep our z-indexes sane, let's **always** import them from here.
 * If you need a new z-index, put it here accordingly and export from here.
 *
 * For instance if you need a hamburger menu that should be in front of any other menu for some reason,
 * and below modals, add hamburger: 3 and modal: 4.
 *
 * Is some weird package or something like that doing weird z-index that you need to workaround?
 * Maybe don't use that plugin, or put it in a new stack context, like wrapping it with a position: relative with z-index: 1,
 * or making its opacity below 1 (opacity: 0.99 - I know, weird right?)
 */

// Change this if you want fractional values
const STEP = 1

// Set as base for all other values
const HEADER_ZINDEX = 2

export default {
  sidebar: HEADER_ZINDEX - STEP,
  header: HEADER_ZINDEX,
  menu: HEADER_ZINDEX + STEP,
  modal: HEADER_ZINDEX + 2 * STEP
}
