import styled from 'styled-components'

import colors from 'styles/colors'

export const Container = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Row = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`

export const Column = styled.div`
  padding: 30px;

  @media (min-width: 481px) {
    width: 50%;
  }
`

export const Text = styled.p`
  font-weight: ${props => props.weight || 600};
  color: ${props => props.color || colors.black};
  text-align: ${props => props.align || 'center'};
  text-shadow: ${props => props.shadow || 'none'};
  ${props => props.italic && `font-style: italic;`};
  ${props => {
    switch (props.size) {
      case 'large':
        return `
          margin-top: 0;
          line-height: 1.2em;
          font-size: 30px;
          margin-bottom: 15px;
          @media (min-width: 320px) {
            font-size: calc(30px + 30 * ((100vw - 320px) / 1580));
            margin-bottom: calc(15px + 25 * ((100vh - 480px) / 900));
          }
          @media (min-width: 768px) {
            font-size: 5.1vmin;
            margin-bottom: 3.6vh;
          }`
      case 'medium':
        return `
          font-size: 18px;
          line-height: 1.2em;
          margin-top: 0;
          margin-bottom: 20px;
          @media (min-width: 320px) {
            font-size: calc(18px + 25 * ((100vw - 320px) / 1580));
            margin-bottom: calc(20px + 20 * ((100vh - 480px) / 420));
          }
          @media (min-width: 768px) {
            line-height: 5vmin;
            font-size: 3.5vmin;
            margin-bottom: 3.5vh;
          }`
      default:
        return `
          font-size: 16px;
          line-height: 1.25;
          @media (min-width: 320px) {
            font-size: calc(16px + 10 * ((100vw - 320px) / 1600));
          }
          @media (min-width: 768px) {
            font-size: 2.5vmin;
          }`
    }
  }};
`
