import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { navigate } from 'gatsby'

import { FORM_ERROR } from 'final-form'

import logger from 'utils/logger'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import { AuthProvider } from 'stores/auth'

import * as api from './api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        LOGIN: 'loading'
      }
    },
    loading: {
      onEntry: ['showLoading', 'submitLogin'],
      on: {
        LOGIN_SUCCESS: 'complete',
        LOGIN_FAILURE: 'error'
      }
    },
    error: {
      on: {
        LOGIN: 'loading'
      },
      onEntry: 'showError'
    },
    complete: {}
  }
}

class LoginContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    this.handleLogin = props.handleLogin
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOGIN_FAILURE':
          this.setError(event)
          break
        case 'LOGIN_SUCCESS':
          this.setSuccess(event)
          break
      }
    }
  }

  setSuccess = async event => {
    const user = get(event, 'data.user')
    const remember = get(event, 'remember')

    await this.handleLogin(user, remember)

    setTimeout(() => {
      navigate(event.navigateNext, { replace: true })
    }, 2000)

    event.callback()
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }
    if (error === 'NETWORK_ERROR') {
      error = 'Please check your internet connection or try again later.'
    }

    event.callback({ [FORM_ERROR]: error })
  }

  submitLogin = async ({ values, callback, ...event }) => {
    const { email, password, remember } = values

    logger.captureBreadcrumb({
      message: 'LoginScreen.submit',
      category: 'auth',
      data: { email, remember }
    })

    try {
      const [error, data] = await api.signIn({ email, password, remember })

      if (error) {
        const isInvalidCredentials =
          (Array.isArray(error) && error[0]?.match(/invalid/i)) ||
          String(error)?.match(/invalid/i)

        if (isInvalidCredentials) {
          logger.captureMessage(error, {
            level: 'info',
            extra: { email }
          })
        } else if (error instanceof Error) {
          logger.captureException(error)
        } else {
          logger.captureException(new Error(error))
        }

        this.transition({ type: 'LOGIN_FAILURE', error, callback })
      } else {
        logger.captureBreadcrumb({
          message: 'LoginScreen.success',
          category: 'auth',
          data: { email, remember }
        })

        this.transition({
          ...event,
          type: 'LOGIN_SUCCESS',
          data,
          remember,
          callback
        })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

class LoginStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new LoginContainer({
      statechart,
      handleLogin: props.handleLogin
    })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}

export default props => (
  <AuthProvider>
    {store => <LoginStoreProvider {...props} handleLogin={store.handleLogin} />}
  </AuthProvider>
)
