import React from 'react'

import { Location, navigate } from '@reach/router'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Form } from 'react-final-form'

import { required } from 'utils/field-validators'
import StateMachine from 'utils/StateMachine'
import { getUrlParameter } from 'utils/parse-querystring'

import LoginStoreProvider from './store'

import Icon from 'components/Icon'
import Input, { ErrorText } from 'components/Input'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'

const LoginModal = () => (
  <Location>
    {({ location }) => (
      <LoginStoreProvider>
        {store => (
          <Form
            initialValues={{ remember: true }}
            onSubmit={(values, _, callback) => {
              const navigateNext =
                getUrlParameter(location, 'next') || routes.APP
              store.transition({
                type: 'LOGIN',
                values,
                callback,
                navigateNext
              })
            }}
            render={({
              handleSubmit,
              submitting,
              invalid,
              submitError,
              submitSucceeded
            }) => {
              if (submitSucceeded) {
                return (
                  <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                    <h1 className='tc mt0'>Welcome back!</h1>

                    <p
                      className='tc lh-copy w-80-ns'
                      style={{ color: colors.blackRGBA(0.7) }}
                    >
                      Redirecting...
                    </p>
                  </div>
                )
              }

              return (
                <form
                  onSubmit={handleSubmit}
                  className='center flex flex-column items-center w-100 w-90-ns mb0'
                >
                  <Input
                    id='email'
                    name='email'
                    label='Email address'
                    autoComplete='email'
                    validate={required}
                    disabled={submitting}
                  />

                  <PasswordInput
                    id='password'
                    name='password'
                    label='Password'
                    autoComplete='current-password'
                    validate={required}
                    disabled={submitting}
                    style={{ marginBottom: 0 }}
                  />

                  <Checkbox
                    name='remember'
                    label='Remember me'
                    className='mv4'
                  />

                  {invalid &&
                    submitError && (
                    <ErrorText center style={{ marginBottom: '2rem' }}>
                      {submitError}
                    </ErrorText>
                  )}

                  <SubmitButton
                    className='mb3'
                    type='submit'
                    color={colors.darkBlueII}
                    colorHover={colors.lightBlue}
                    bgColor={colors.lightBlueI}
                    bgColorHover={colors.darkIce}
                    disabled={submitting}
                    expand
                    expandWidth='100%'
                  >
                    {submitting ? 'LOGGING...' : 'LOG IN'}
                  </SubmitButton>

                  <Button
                    link
                    onClick={() => {
                      navigate(`${location.pathname}${routes.FORGOT_PASSWORD}`)
                    }}
                  >
                    Forgot password?
                  </Button>
                </form>
              )
            }}
          />
        )}
      </LoginStoreProvider>
    )}
  </Location>
)

const SubmitButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;

  @media (max-width: 480px) {
    width: 100%;
    font-size: 16px;
  }
`

const statechart = {
  key: 'password-visibility',
  initial: 'off',
  states: {
    off: {
      on: { TOGGLE: 'on' }
    },
    on: {
      on: { TOGGLE: 'off' },
      onEntry: 'show'
    }
  }
}

export const PasswordInput = props => (
  <StateMachine statechart={statechart} renderOnAction='show'>
    {(state, visible) => (
      <PasswordWrapper>
        <Input type={visible ? 'text' : 'password'} {...props} />

        <TogglePasswordButton
          visible={visible}
          onClick={() => state.transition('TOGGLE')}
        >
          <Icon name={visible ? 'eye-blocked' : 'eye'} />
        </TogglePasswordButton>
      </PasswordWrapper>
    )}
  </StateMachine>
)

const PasswordWrapper = styled.div`
  width: 100%;
  position: relative;
`

const TogglePasswordButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 5px;
  right: 0;
  padding: 0 16px;
  height: 56px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:focus,
  &:active {
    outline: 0;
  }

  > svg {
    color: ${props =>
    props.visible ? colors.blackRGBA(0.5) : colors.blackRGBA(0.2)};
    height: 24px;
    transition: color 0.25s ease;
  }

  &:hover {
    > svg {
      color: ${colors.blackRGBA(0.5)};
    }
  }
`

export default LoginModal
