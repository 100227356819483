import React, { Fragment, Component } from 'react'

import { Router, Location, Link as ReachLink } from '@reach/router'
import { Link } from 'gatsby'

import * as routes from 'config/routes'

import styled, { css } from 'styled-components'

import colors from 'styles/colors'
import zIndex from 'styles/zIndex'

import { AuthProvider } from 'stores/auth'

import { getFullName, getUserPicture } from 'utils/profile-info'

import Icon from './Icon'

const defaultPages = []

const authPages = [
  { title: 'Event Flight Watcher', path: routes.EVENTS },
  { title: 'Sign Up', path: routes.SIGNUP },
  { title: 'Log In', path: routes.LOGIN }
]

const appPages = [
  // { title: 'Prime', path: `${routes.PRIME}/` },
  { title: 'Map', path: routes.EXPLORE },
  { title: 'Messages', path: routes.MESSAGES },
  { title: 'Stays', path: routes.STAYS },
  { title: 'Settings', path: routes.SETTINGS, dropdown: true },
  { title: 'Log out', path: routes.LOGOUT, dropdown: true }
]

function getOpaqueHeader ({ pathname }, isLoggedIn) {
  // set of pages that need to have opaque header
  const pages = [routes.EVENTS, routes.PRIME]

  const route =
    pathname.substr(-1) === '/'
      ? pathname.substr(0, pathname.length - 1)
      : pathname

  if (pages.join(',').indexOf(route) !== -1 || isLoggedIn) {
    return true
  }
}

const Header = () => (
  <AuthProvider>
    {store => {
      const user = store.getCurrentUser()
      const isLoggedIn = store.isLoggedIn()

      return (
        <Location>
          {({ location }) => {
            const opaqueHeader = getOpaqueHeader(location, isLoggedIn)
            const showFiller = location.pathname !== '/'

            return (
              <Fragment>
                <HeaderContainer isLoggedIn={opaqueHeader}>
                  <a
                    href='https://www.staycircles.com'
                    title='StayCircles'
                    className='ph2 db db-ns'
                  >
                    <Logo
                      // name={isLoggedIn || opaqueHeader ? 'sc-logo' : 'sc-mark'}
                      name='sc-logo'
                    />
                  </a>

                  <Router primary={false}>
                    <Navigation
                      default
                      pages={defaultPages}
                      user={user}
                      isLoggedIn={isLoggedIn}
                      opaqueHeader={getOpaqueHeader}
                    />
                    <Navigation
                      path={`${routes.APP}/*`}
                      pages={appPages}
                      user={user}
                      isLoggedIn={isLoggedIn}
                      opaqueHeader={getOpaqueHeader}
                    />
                  </Router>
                </HeaderContainer>
                {showFiller && <HeaderFiller>{'\u00A0'}</HeaderFiller>}
              </Fragment>
            )
          }}
        </Location>
      )
    }}
  </AuthProvider>
)

class Navigation extends Component {
  state = { dropdownVisible: false, isLoggedIn: false }

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside, false)

    if (this.props.isLoggedIn) {
      this.setState({ isLoggedIn: true })
    }
  }

  componentDidUpdate (prevProps) {
    // The navigation was having trouble to re-render
    // so this hack gives it a little help
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.setState({ isLoggedIn: true })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside, false)
  }

  handleClickOutside = e => {
    if (!this.state.dropdownVisible || this.nav.contains(e.target)) {
      return
    }

    this.toggle()
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownVisible: !prevState.dropdownVisible
    }))
  }

  render () {
    const { pages, user, opaqueHeader } = this.props
    const { dropdownVisible, isLoggedIn } = this.state

    const name = getFullName(user)
    const picture = getUserPicture(user)
    const userAvatar = {
      title: name,
      path: '/app',
      user: true,
      picture
    }

    let allPages = pages

    if (!isLoggedIn) {
      allPages = [...pages, ...authPages]
    } else if (this.props.default) {
      const otherPages = appPages.map(page => ({ ...page, app: true }))
      allPages = [...pages, ...otherPages, userAvatar]
    } else {
      allPages = [...pages, userAvatar]
    }

    return (
      <div
        ref={node => {
          this.nav = node
        }}
      >
        <DropdownBtn onClick={this.toggle}>
          {!isLoggedIn ? (
            <Icon name='hamburger-menu' height={24} color={colors.black} />
          ) : (
            <Fragment>
              <UserPicture src={picture} alt={`${name}'s picture`} />
              <CaretIcon />
            </Fragment>
          )}
        </DropdownBtn>

        <NavList
          visible={dropdownVisible}
          isLoggedIn={isLoggedIn}
          opaqueHeader={opaqueHeader}
          pages={allPages}
          toggleDropdown={this.toggle}
        />

        {this.props.default && isLoggedIn && (
          <NavList
            visible={dropdownVisible}
            isLoggedIn={isLoggedIn}
            pages={appPages}
            toggleDropdown={this.toggle}
            userDropdown
          />
        )}

        {!this.props.default && isLoggedIn && (
          <NavList
            visible={dropdownVisible}
            isLoggedIn={isLoggedIn}
            pages={appPages.filter(page => page.dropdown)}
            toggleDropdown={this.toggle}
            userDropdown
          />
        )}
      </div>
    )
  }
}

const removeTrailingSlash = str => str.replace(/(^\/)/, '').replace(/(\/$)/, '')

const isLinkActive = ({ location, href }) => {
  const pathname = removeTrailingSlash(location.pathname)
  let link = href.split('?')[0]
  link = removeTrailingSlash(link)

  if (
    pathname &&
    link &&
    ((link !== 'app' && pathname.match(link)) || pathname === link)
  ) {
    return { active: 1 }
  }
}

const Anchor = props => (
  <a href={props.to} target='blank'>
    {props.children}
  </a>
)

const NavList = ({
  visible,
  isLoggedIn,
  opaqueHeader,
  pages,
  toggleDropdown,
  userDropdown
}) => (
  <NavContainer
    visible={visible}
    isLoggedIn={isLoggedIn || opaqueHeader}
    userDropdown={userDropdown}
  >
    {pages.map(page => {
      let path = page.path || page.getPath()

      const login = authPages.some(p => p.path === path)
      const LinkComponent = page.external ? Anchor : login ? ReachLink : Link

      if (login && isLoggedIn) return null

      return (
        <li
          key={path}
          app={page.app && 1}
          user={page.user && 1}
          dropdown={page.dropdown && 1}
          button={page.button && 1}
          external={page.external && 1}
          login={login ? 1 : undefined}
        >
          <Location>
            {({ location }) => {
              if (login && path.startsWith('?')) {
                path = `${location.pathname}${path}`
              }

              return (
                <LinkComponent
                  to={path}
                  getProps={login ? undefined : isLinkActive}
                  onClick={e => {
                    if (path && !page.user) {
                      toggleDropdown && toggleDropdown()
                    }
                    if (page.user) {
                      e.preventDefault()
                      toggleDropdown && toggleDropdown()
                    }
                  }}
                >
                  {page.picture && (
                    <UserPicture
                      src={page.picture}
                      alt={`${page.title}'s picture`}
                    />
                  )}

                  {page.title}

                  {page.user && <CaretIcon />}
                </LinkComponent>
              )
            }}
          </Location>
        </li>
      )
    })}
  </NavContainer>
)

export const headerHeight = '60px'
export const headerHeightMobile = '60px'

const HeaderContainer = styled.div`
  padding: 0 1rem;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${zIndex.header};
  top: 0;
  left: 0;
  right: 0;
  // margin-top: 10px;

  height: ${headerHeightMobile};

  @media (min-width: 768px) {
    height: ${headerHeight};
  }

  @media (max-width: 479px) {
    justify-content: space-between;
  }

  ${({ isLoggedIn }) =>
    isLoggedIn &&
    `
    position: fixed;
    background-color: #fff;
    border-bottom: 1px solid ${colors.blackRGBA(0.05)};
    margin-top: 0px;
  `};
`

const Logo = styled(Icon)`
  color: #333;
  height: 24px;

  @media (min-width: 1921px) {
    height: 36px;
  }
`

const HeaderFiller = styled.div`
  width: 100%;
  height: ${headerHeightMobile};

  @media (min-width: 768px) {
    height: ${headerHeight};
  }
`

const inlineNav = css`
  flex-direction: row;
  height: 100%;
  align-items: center;

  li {
    display: inline;

    &:not([user]) {
      a {
        display: inline-block;
        margin: 0 1rem;
        color: ${({ isLoggedIn }) =>
    isLoggedIn ? colors.darkBlueI : colors.white};
        height: ${headerHeightMobile};
        line-height: ${headerHeightMobile};

        @media (min-width: 768px) {
          height: ${headerHeight};
          line-height: ${headerHeight};
        }

        &:hover {
          border-bottom: 3px solid
            ${({ isLoggedIn }) =>
    isLoggedIn ? colors.darkBlueI : colors.white};
        }
      }

      &:not([login]) a {
        &:focus,
        &[active] {
          border-bottom: 3px solid
            ${({ isLoggedIn }) =>
    isLoggedIn ? colors.darkBlueI : colors.white};
        }
      }
    }

    &[button] a {
      display: inline;
      padding: 0.5rem 1rem;
      font-weight: 600;
      color: ${colors.blue};
      border: 2px solid ${colors.blue};
      border-radius: 6px;

      &:hover {
        color: ${colors.white};
        background-color: ${colors.blue};
        border-bottom-width: 2px;
      }

      &:active {
        color: ${colors.whiteRGBA(0.6)};
      }
    }
  }
`

const hiddenNav = css`
  display: none;
`

const dropdownNav = css`
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  z-index: ${zIndex.header};
  top: 40px;
  right: 24px;
  border: 1px solid ${colors.blackRGBA(0.1)};
  box-shadow: 2px 2px 4px ${colors.blackRGBA(0.05)};
  align-items: flex-start;

  li {
    display: inline-block;
  }

  li > a {
    padding: 1.5rem 2rem;
    min-width: 200px;
    display: block;
    text-align: left;
    font-weight: 600;

    &:hover,
    &:focus,
    &[active] {
      color: ${colors.blue};
    }

    &:active {
      color: ${colors.darkerBlue};
    }
  }
`

const NavContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1079px) {
    ${props =>
    !props.userDropdown && props.visible ? dropdownNav : hiddenNav};
  }

  @media (min-width: 1080px) {
    ${props =>
    props.userDropdown
      ? props.visible
        ? dropdownNav
        : hiddenNav
      : inlineNav};
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.blackRGBA(0.6)};
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &[active] {
      color: ${colors.blackRGBA(0.8)};
    }

    &:active {
      color: ${colors.blackRGBA(0.4)};
    }
  }

  @media (min-width: 1080px) {
    li[app] a {
      display: none;
    }

    li[dropdown] {
      ${props =>
    !props.userDropdown &&
        css`
          display: none;
        `};
    }

    li[external] {
      margin-right: 1rem;
      position: relative;

      &:after {
        content: '';
        height: 30%;
        width: 1px;
        background: rgba(0, 0, 0, 0.2);
        display: block;
        position: absolute;
        right: -0.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  ${props =>
    props.isLoggedIn &&
    css`
      li[user]:last-child a {
        @media (max-width: 1079px) {
          display: none;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 1rem;
        color: ${colors.blackRGBA(0.7)};

        &:hover,
        &:focus {
          color: ${colors.darkBlue};
          background: ${colors.blackRGBA(0.03)};
          border-radius: 5rem;

          svg {
            color: ${colors.darkBlue};
          }
        }
      }
    `};
`

const DropdownBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  @media (min-width: 1080px) {
    display: none;
  }
`

DropdownBtn.defaultProps = {
  type: 'button'
}

const UserPicture = styled.img`
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem 0 0;
  border-radius: 100%;
  box-shadow: inset 0px 0px 3px ${colors.blackRGBA(0.2)};
`

const CaretIcon = styled(Icon).attrs({ name: 'caret' })`
  color: ${colors.blackRGBA(0.5)};
  height: 10px;

  @media (min-width: 1080px) {
    margin-left: 0.5rem;
  }
`

export default Header
