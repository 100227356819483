import React from 'react'

import MatchQueryModal from 'components/MatchQueryModal'

import LoginSignupModal from './LoginSignup'
import SendResetPasswordModal from './SendResetPassword'
import ResetPasswordModal from './ResetPassword'
import UnsubscribeModal from './UnsubscribeModal'
import AdventureMachineModal from './AdventureMachine'

const MODALS = [
  {
    query: 'login',
    Component: LoginSignupModal,
    props: { tab: 'login', hideLoggedIn: true }
  },
  {
    query: 'forgot_password',
    Component: SendResetPasswordModal,
    props: { hideLoggedIn: true }
  },
  { query: 'reset_password_token', Component: ResetPasswordModal },
  {
    query: 'signup',
    Component: LoginSignupModal,
    props: { tab: 'signup', hideLoggedIn: true }
  },
  { query: 'unsubscribe_type', Component: UnsubscribeModal },
  { query: 'preference_type', Component: UnsubscribeModal },
  { query: 'get_adventure_machine', Component: AdventureMachineModal }
]

export default () => <MatchQueryModal modalsList={MODALS} />
