import React from 'react'
import PropTypes from 'prop-types'

import { FORM_ERROR } from 'final-form'

import logger from 'utils/logger'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import * as api from './api'
import * as verifyapis from 'pages/signup/_api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        SUBMIT: 'loading'
      }
    },
    loading: {
      onEntry: ['showLoading', 'submitSignUp'],
      on: {
        SUCCESS: 'complete',
        FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: {
        SUBMIT: 'loading',
        RESEND: 'loading'
      }
    },
    complete: {
      on: {
        RESEND: 'loading',
        VERIFYOTP: 'verifying'
      }
    },
    verifying: {
      onEntry: ['showVerifying', 'verifyOtp'],
      on: {
        VERIFY_FAILURE: 'verifyerror',
        VERIFY_SUCCESS: 'otpVerfied'
      }
    },
    verifyerror: {
      onEntry: 'showVerifyError',
      on: {
        RESEND: 'loading',
        VERIFYOTP: 'verifying'
      }
    },
    otpVerfied: {
      onEntry: ['verifySuccess']
    }
  }
}

class SignUpContainer extends StateMachineContainer {
  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'FAILURE':
          this.setError(event)
          break
        case 'SUCCESS':
          this.setSuccess(event)
          break
        case 'VERIFY_SUCCESS':
          this.setVerifySuccess(event)
          break
        case 'VERIFY_FAILURE':
          this.setVerifyError(event)
          break
      }
    }
  }

  setSuccess = async event => {
    if (event.callback) {
      event.callback()
    }
  }

  setError = event => {
    if (event.error) {
      let error = event.error

      if (Array.isArray(error)) {
        error = error[0]
      }

      event.callback({ [FORM_ERROR]: error })
    }
  }

  setVerifyError = async event => {
    if (event.error) {
      let error = event.error

      if (Array.isArray(error)) {
        error = error[0]
      }

      await this.setState({ error })
    }
  }

  setVerifySuccess = async event => {
    if (event.code) {
      await this.setState({ code: event.code })
    }
  }

  submitSignUp = async ({ values: { email }, callback, ...event }) => {
    logger.captureBreadcrumb({
      message: 'SignUpScreen.submit',
      category: 'auth',
      data: { email }
    })

    try {
      const [error, data] = await api.signUpEmail(email)

      if (error) {
        logger.captureException(error)
        this.transition({ type: 'FAILURE', error, callback })
      } else {
        logger.captureBreadcrumb({
          message: 'SignUpScreen.success',
          category: 'auth',
          data: { email }
        })
        if (
          data.message &&
          data.message.toLowerCase().includes('email already exists')
        ) {
          return this.transition({
            type: 'FAILURE',
            error: data.message,
            callback
          })
        }
        this.transition({ ...event, type: 'SUCCESS', data, callback })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  verifyOtp = async ({ code, ...event }) => {
    try {
      const [error, data] = await verifyapis.checkSignupToken(code)

      if (error) {
        logger.captureException(error)

        this.transition({ type: 'VERIFY_FAILURE', error })
        return null
      } else {
        logger.captureBreadcrumb({
          message: 'SignUp.checkToken.success checkSignupToken',
          category: 'auth'
        })
        if (data) {
          this.transition({ type: 'VERIFY_SUCCESS', code })
        }
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

export default class SignUpStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new SignUpContainer({ statechart })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
