import api from 'utils/api'

export const signIn = ({ email, password, remember }) => {
  const data = {
    password,
    email: formatEmail(email),
    remember_me: remember
  }

  return api.post('/auth/sign_in/', data)
}

/**
 * Helpers
 */

function formatEmail (email) {
  return email.trim().toLowerCase()
}
