import React from 'react'
import PropTypes from 'prop-types'

import { FORM_ERROR } from 'final-form'

import logger from 'utils/logger'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import * as api from './api'
import * as verifyapis from 'components/AuthQueryModals/ResetPassword/api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        SUBMIT: 'loading'
      }
    },
    loading: {
      onEntry: ['showLoading', 'submit'],
      on: {
        SUCCESS: 'complete',
        FAILURE: 'error'
      }
    },
    error: {
      on: {
        SUBMIT: 'loading',
        RESEND: 'loading'
      },
      onEntry: 'showError'
    },
    complete: {
      on: {
        RESEND: 'loading',
        VERIFYOTP: 'verifying'
      }
    },
    verifying: {
      onEntry: ['showVerifying', 'verifyOtp'],
      on: {
        VERIFY_FAILURE: 'verifyerror',
        VERIFY_SUCCESS: 'otpVerfied'
      }
    },
    verifyerror: {
      onEntry: 'showVerifyError',
      on: {
        RESEND: 'loading',
        VERIFYOTP: 'verifying'
      }
    },
    otpVerfied: {
      onEntry: ['verifySuccess']
    }
  }
}

class SendResetPasswordContainer extends StateMachineContainer {
  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'FAILURE':
          this.setError(event)
          break
        case 'SUCCESS':
          this.setSuccess(event)
          break
        case 'VERIFY_SUCCESS':
          this.setVerifySuccess(event)
          break
        case 'VERIFY_FAILURE':
          this.setVerifyError(event)
          break
      }
    }
  }

  setSuccess = async event => {
    if (event.callback) {
      event.callback()
    }
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }

    event.callback({ [FORM_ERROR]: error })
  }

  setVerifyError = async event => {
    if (event.error) {
      let error = event.error

      if (Array.isArray(error)) {
        error = error[0]
      }

      await this.setState({ error })
    }
  }

  setVerifySuccess = async event => {
    if (event.code) {
      await this.setState({ code: event.code })
    }
  }

  submit = async ({ values, callback }) => {
    const { email } = values

    logger.captureBreadcrumb({
      message: 'SendResetPassword.submit',
      category: 'auth',
      data: { email }
    })

    try {
      const [error] = await api.requestResetPassword({ email })

      if (error) {
        if (error instanceof Error) {
          logger.captureException(error)
        } else {
          logger.captureException(new Error(error))
        }

        this.transition({ type: 'FAILURE', error, callback })
      } else {
        logger.captureBreadcrumb({
          message: 'SendResetPassword.success',
          category: 'auth',
          data: { email }
        })

        this.transition({ type: 'SUCCESS', callback })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  verifyOtp = async ({ code, ...event }) => {
    try {
      const [error, data] = await verifyapis.checkToken(code)

      if (error) {
        logger.captureException(error)

        this.transition({ type: 'VERIFY_FAILURE', error })
        return null
      } else {
        logger.captureBreadcrumb({
          message: 'SignUp.checkToken.success checkSignupToken',
          category: 'auth'
        })
        if (data) {
          this.transition({ type: 'VERIFY_SUCCESS', code })
        }
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

export default class SendResetPasswordStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new SendResetPasswordContainer({ statechart })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
