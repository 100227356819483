import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { navigate } from 'gatsby'

import { FORM_ERROR } from 'final-form'

import * as routes from 'config/routes'

import logger from 'utils/logger'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import { handleLogin } from 'stores/auth'

import * as api from './api'

const statechart = {
  initial: 'checkingToken',
  states: {
    checkingToken: {
      onEntry: ['showLoading'],
      on: {
        VALID: 'idle',
        FAILURE: 'error'
      }
    },
    idle: {
      on: {
        SUBMIT: 'loading'
      }
    },
    loading: {
      onEntry: ['submit'],
      on: {
        SUCCESS: 'complete',
        FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: {
        SUBMIT: 'loading'
      }
    },
    complete: {}
  }
}

class ResetPasswordContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      token: props.token,
      data: {},
      error: null
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'FAILURE':
          this.setError(event)
          break
        case 'VALID':
        case 'SUCCESS':
          this.setSuccess(event)
          break
      }
    }
  }

  setSuccess = async event => {
    if (event.callback) {
      const user = get(event, 'data.user')

      await handleLogin(user, true)

      setTimeout(() => {
        navigate(routes.APP)
      }, 2000)

      event.callback()
    } else {
      this.setState({ data: event.data })
    }
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }

    if (event.callback) {
      event.callback({ [FORM_ERROR]: error })
    } else {
      this.setState({ error })
    }
  }

  submit = async ({ values, callback }) => {
    const { token } = this.state

    logger.captureBreadcrumb({
      message: 'ResetPassword.submit',
      category: 'auth',
      data: { token }
    })

    try {
      const resetPasswordToken = token

      const [error, data] = await api.resetPassword({
        resetPasswordToken,
        ...values
      })

      if (error) {
        if (error instanceof Error) {
          logger.captureException(error)
        } else {
          logger.captureException(new Error(error))
        }

        this.transition({ type: 'FAILURE', error, callback })
      } else {
        logger.captureBreadcrumb({
          message: 'ResetPassword.success',
          category: 'auth',
          data: { token }
        })

        this.transition({ type: 'SUCCESS', data, callback })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  checkToken = async token => {
    logger.captureBreadcrumb({
      message: 'ResetPassword.checkToken',
      category: 'auth',
      data: { token }
    })

    try {
      const [error, data] = await api.checkToken(token)

      if (error) {
        if (error instanceof Error) {
          logger.captureException(error)
        } else {
          logger.captureException(new Error(error))
        }

        this.transition({ type: 'FAILURE', error })
      } else if (!data.validToken) {
        this.transition({
          type: 'FAILURE',
          error:
            'Oops something went wrong. Please try resetting your password again.'
        })
      } else {
        logger.captureBreadcrumb({
          message: 'ResetPassword.checkToken.success',
          category: 'auth',
          data
        })

        this.transition({ type: 'VALID', data })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

export default class ResetPasswordStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    const { token } = props
    this.container = new ResetPasswordContainer({ statechart, token })
  }

  componentDidMount () {
    const { token } = this.props
    this.container.checkToken(token)
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
