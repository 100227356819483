import React from 'react'

import get from 'lodash/get'

import { Location } from '@reach/router'

import navigateBack from 'utils/navigateBack'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Form } from 'react-final-form'

import {
  composeValidators,
  required,
  minLength,
  maxLength
} from 'utils/field-validators'

import queryString from 'utils/parse-querystring'

import ResetPasswordStoreProvider from './store'

import Modal from 'components/Modal'
import Input, { ErrorText } from 'components/Input'
import Button from 'components/Button'

const confirmValidator = (value, allValues) => {
  return value !== get(allValues, 'password') ? 'Invalid' : undefined
}

const ResetPasswordModal = () => (
  <Location>
    {({ location }) => {
      const query = queryString(location.search)
      const token = get(query, 'reset_password_token')
      return (
        <Modal
          isOpen
          onRequestClose={navigateBack}
          contentLabel='Reset Password'
        >
          <ResetPasswordStoreProvider token={token}>
            {store => (
              <Form
                onSubmit={(values, _, callback) => {
                  store.transition({ type: 'SUBMIT', values, callback })
                }}
                render={({
                  handleSubmit,
                  submitting,
                  submitSucceeded,
                  invalid,
                  submitError
                }) => {
                  if (store.matchesActions('showLoading')) {
                    return (
                      <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                        <p
                          className='tc lh-copy w-80-ns'
                          style={{ color: colors.blackRGBA(0.7) }}
                        >
                          Loading...
                        </p>
                      </div>
                    )
                  }

                  if (
                    store.matchesActions('showError') &&
                    get(store, 'state.error')
                  ) {
                    return (
                      <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                        <ErrorText center>
                          {get(store, 'state.error')}
                        </ErrorText>
                      </div>
                    )
                  }

                  if (submitSucceeded) {
                    return (
                      <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                        <h1 className='tc mt0'>
                          Password changed successfully.
                        </h1>

                        <p
                          className='tc lh-copy w-80-ns'
                          style={{ color: colors.blackRGBA(0.7) }}
                        >
                          Logging in...
                        </p>
                      </div>
                    )
                  }

                  return (
                    <form
                      onSubmit={handleSubmit}
                      className='center flex flex-column items-center w-100 w-90-ns mv4'
                    >
                      <h1 className='tc mt0'>Reset password</h1>

                      <p
                        className='tc mb4 lh-copy w-80-ns'
                        style={{ color: colors.blackRGBA(0.7) }}
                      >
                        Create a new password for your account
                      </p>

                      <Input
                        id='password'
                        name='password'
                        type='password'
                        label='New password (8+ characters)'
                        autoComplete='new-password'
                        disabled={submitting}
                        validate={composeValidators(
                          required,
                          minLength(8),
                          maxLength(50)
                        )}
                      />

                      <Input
                        id='passwordConfirmation'
                        name='passwordConfirmation'
                        type='password'
                        label='Confirm password'
                        autoComplete='new-password'
                        disabled={submitting}
                        validate={composeValidators(
                          required,
                          minLength(8),
                          maxLength(50),
                          confirmValidator
                        )}
                      />

                      {invalid &&
                        submitError && (
                        <ErrorText center style={{ marginBottom: '2rem' }}>
                          {submitError}
                        </ErrorText>
                      )}

                      <SubmitButton
                        type='submit'
                        color={colors.white}
                        colorHover={colors.offwhite}
                        bgColor={colors.red}
                        bgColorHover={colors.darkRed}
                        disabled={submitting}
                        expand
                        expandWidth='100%'
                        className='mt3'
                      >
                        {submitting ? 'LOADING...' : 'UPDATE PASSWORD'}
                      </SubmitButton>
                    </form>
                  )
                }}
              />
            )}
          </ResetPasswordStoreProvider>
        </Modal>
      )
    }}
  </Location>
)

const SubmitButton = styled(Button)`
  letter-spacing: 2px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

export default ResetPasswordModal
