import React from 'react'

import { push } from 'utils/parse-querystring'
import root from 'utils/windowOrGlobal'
import Modal from 'components/Modal'

import { Text } from 'components/Landings/Explained'

import styled from 'styled-components'
import colors from 'styles/colors'

const navigateBack = () =>
  push({
    pathname: root.location.pathname
  })

const AdventureMachineModal = () => {
  return (
    <Modal
      isOpen
      onRequestClose={navigateBack}
      contentLabel='Adventure Machine'
    >
      <RowAM className='flex flex-row'>
        <ColumnAM className='flex flex-column mv2'>
          <Wrapper>
            <LandingText size='small'>Adventure Machine</LandingText>
            <HelperText className='mt2-ns'>Get the app</HelperText>
          </Wrapper>

          <StoreWrapper className='flex flex-wrap'>
            <a
              target='_blank'
              href='https://itunes.apple.com/us/app/adventure-machine/id1412862317?ls=1&mt=8'
              onClick={ga(
                'send',
                'event',
                'AM App Store Link',
                'open',
                'iOS App Store'
              )}
            >
              <StoreIcon
                src='https://res.cloudinary.com/staycircles/image/upload/v1572942190/assets/sc_web_landing/download-on-the-app-store-apple_1.png'
                className='mb4-ns mb2'
              />
            </a>

            <a
              target='_blank'
              href='https://play.google.com/store/apps/details?id=com.staycircles.am.android'
              onClick={ga(
                'send',
                'event',
                'AM App Store Link',
                'open',
                'Android Play Store'
              )}
            >
              <StoreIcon src='https://res.cloudinary.com/staycircles/image/upload/v1573449692/assets/sc_web_landing/google-play-badge_1_1.png' />
            </a>
          </StoreWrapper>
        </ColumnAM>

        <PhoneImage src='https://res.cloudinary.com/staycircles/image/upload/v1572942136/assets/sc_web_landing/Adventure_Machine_Phone_1.png' />
      </RowAM>
    </Modal>
  )
}

const ga = (...args) => root && root.ga && root.ga(...args)

const RowAM = styled.div`
  padding: 0px 20px 0px 20px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`
const ColumnAM = styled.div`
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`
const Wrapper = styled.div`
  justify-content: center;
  flex-direction: row;
  @media (max-width: 600px) {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`
const StoreWrapper = styled.div`
  @media (max-width: 600px) {
    margin-bottom: 5px;
    flex-direction: column;
    align-items: center;
  }
`
const LandingText = styled(Text)`
  color: ${colors.darkestBlue};
  font-size: 18px;
  margin-bottom: 15px;
  text-align: left;
  line-height: 1;
  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`
const PhoneImage = styled.img`
  height: 300px;
`
const StoreIcon = styled.img`
  width: 199px;
  max-width: 100%;
  height: auto;
`

const HelperText = styled.span`
  font-size: 16px;
  color: ${colors.darkestBlue};
`

export default AdventureMachineModal
