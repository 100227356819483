import root from 'utils/windowOrGlobal'
import { navigate } from 'gatsby'

export default function navigateBack () {
  // Only go back if previous page won't go out of site
  // Otherwise go back to home
  if (root.history.state) {
    root.history.back()
  } else {
    navigate('/')
  }
}
