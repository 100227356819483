import React from 'react'
import styled from 'styled-components'
// import AppleSignin from 'react-apple-signin-auth'

import Login from '../Login'
import Signup from '../SignUp'
import { push } from 'utils/parse-querystring'
import root from 'utils/windowOrGlobal'
import Modal from 'components/Modal'
import colors from 'styles/colors'
import GoogleSignInButton from './GoogleSignIn'
import AppleSigninButton from './AppleSignIn'

const TABS = [
  { name: 'Create Account', key: 'signup' },
  { name: 'Log in', key: 'login' }
]

const navigateBack = () =>
  push({
    pathname: root.location.pathname
  })

export default class LoginSignupModal extends React.Component {
  constructor (props) {
    super(props)
    const { tab = 'login' } = props

    this.state = {
      currentTab: tab || 'login'
    }
  }

  onChangeTab = currentTab => () => this.setState({ currentTab })

  render () {
    const { currentTab = '' } = this.state

    return (
      <Modal isOpen onRequestClose={navigateBack} contentLabel='Auth Modal'>
        <TabContainer className='flex flex-row justify-around mb4-ns mb3'>
          {TABS.map(({ name: tab, key }) => (
            <TabAnchor
              onClick={this.onChangeTab(key)}
              style={{ borderColor: colors.darkBlue }}
              selected={currentTab === key}
              className={`dtc-ns tc bg-transparent v-mid ${!currentTab ===
                key && 'pointer bn'}`}
              key={key}
              data-rh={tab}
            >
              {tab}
            </TabAnchor>
          ))}
        </TabContainer>
        {currentTab === 'login' ? <Login /> : <Signup />}
        <OauthButtonsWrapper className='center flex flex-column items-center w-100 w-90-ns mb0'>
          <GoogleSignInButton />
          <AppleSigninButton className='w-100' />
          {/* <AppleSignin
            authOptions={{
              clientId: 'com.staycircles.appstaging.webapp',
              redirectURI: 'https://appstaging.staycircles.com',
              scope: 'name',
              state: 'org:webapp',
              usePopup: true
            }}
            className='w-100'
            onSuccess={response => {
              console.log(response)
              {
                  "authorization": {
                    "state": "[STATE]",
                    "code": "[CODE]",
                    "id_token": "[ID_TOKEN]"
                  },
                  "user": {
                    "email": "[EMAIL]",
                    "name": {
                      "firstName": "[FIRST_NAME]",
                      "lastName": "[LAST_NAME]"
                    }
                  }
              }
            }}
          /> */}
        </OauthButtonsWrapper>
      </Modal>
    )
  }
}

const TabAnchor = styled.a`
  padding: 15px 55px;
  color: ${colors.darkBlueII};
  font-size: 18px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    padding: ${({ selected }) => (selected ? '10px 20px' : '0px')};
  }

  ${({ selected }) =>
    selected &&
    `
          background-color: ${colors.lightBlueI};
          border-radius: 5px;
          `};
`

const TabContainer = styled.div`
  align-items: center;
`

const OauthButtonsWrapper = styled.div`
  gap: 8px;
`
